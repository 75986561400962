import React, { useState } from "react"
import { Row, Col } from "react-flexbox-grid"
import VisibilitySensor from "react-visibility-sensor"

import Hero from "../../components/Hero"
import Layout from "../../components/Layout"
import ContentBlock from "../../components/ContentBlock"
import HelmetWrapper from "../../components/HelmetWrapper"

import circleImg from "../../assets/images/sm_circle_KnowNarcolepsy.png"
import headerHero from "../../assets/images/header_focus.jpg"
import infographicFull from "../../assets/images/infographic.png"
import infographic1 from "../../assets/images/infographic1.png"
import infographic2 from "../../assets/images/infographic2.png"
import infographic3 from "../../assets/images/infographic3.png"
import infographic4 from "../../assets/images/infographic4.png"
import CustomContentProgressbar from "../../components/CustomContentProgressbar"

import styles from "react-circular-progressbar/dist/styles.css"
import "./index.scss"

const pageTitle = "Narcolepsy, Rare Neurological Diseases | Harmony Biosciences"
const pageDescription =
  "Narcolepsy is a rare, chronic debilitating neurological disorder of sleep-wake state instability. Learn more about this rare disease from our survey results."

const pageKeywords = "Narcolepsy"

const percentage = {
  eightySix: "86",
}

const OurFocus = () => {
  const [renderProgress, setRenderProgress] = useState(false)

  const learnMoreBtn = () => {
    const isGA = typeof window.ga !== "undefined"

    if (isGA) {
      window.ga(
        "send",
        "event",
        "External Link",
        "click",
        "Learn More - Patients"
      )
    }
    window.open("https://knownarcolepsy.com/", "_blank")
  }

  const downloadBtn = () => {
    const isGA = typeof window.ga !== "undefined"

    if (isGA) {
      window.ga("send", "event", "Button", "click", "Infographic - PDF")
    }
    window.open("/static/KnowNarcolepsy_infographic.pdf", "_blank")
  }
  return (
    <Layout jobCode="US-HAR-2200050/Jun 2022">
      <div id="our-focus">
        <HelmetWrapper
          pageTitle={pageTitle}
          pageDescription={pageDescription}
          pageKeywords={pageKeywords}
          slug="/our-focus"
        />
        <Hero bg={headerHero}>
          <h1>About Narcolepsy</h1>
        </Hero>
        <ContentBlock color="orange">
          <Row>
            <Col xs={12} md={2}>
              <Row center="xs">
                <Col>
                  <img src={circleImg} className="" />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={10}>
              <p>
                Narcolepsy is a rare, chronic, debilitating neurological
                disorder of sleep-wake state instability.{" "}
                <strong>
                  Narcolepsy impacts approximately 165,000 Americans
                </strong>{" "}
                and is primarily characterized by excessive daytime sleepiness,
                cataplexy, and other manifestations of REM sleep dysregulation,
                which intrude into wakefulness. In most patients, it is caused
                by the loss of hypocretin, a neuropeptide in the brain that
                supports sleep-wake state stability.
              </p>
              <p>
                This disorder affects men and women equally, with typical
                symptom onset in adolescence or young adulthood; however, it can
                take up to a decade to be properly diagnosed. Narcolepsy can
                cause significant burden on patients and their families,
                affecting their ability to perform routine tasks, limit
                achievement at school and work, impact social relationships, and
                cause impairment in overall quality of life.
              </p>
              <a
                href="javascript:void(0);"
                onClick={learnMoreBtn}
                target="_blank"
              >
                <button aria-label="Learn More">Learn More</button>
              </a>
            </Col>
          </Row>
        </ContentBlock>
        <ContentBlock color="gray2" className="hide-below-635 pb-zero">
          <Row center="xs">
            <Col xs={12}>
              <img
                src={infographic1}
                alt="2018 survey of healthcare providers and people living with narcolepsy highlights the need for more education & new treatment options."
                className=""
              />
            </Col>
          </Row>

          <VisibilitySensor>
            {({ isVisible }) => {
              let percentage86 = isVisible ? 86 : 0
              let percentage68 = isVisible ? 68 : 0
              let percentage80 = isVisible ? 80 : 0
              let percentage76 = isVisible ? 76 : 0
              return (
                <Row center="xs" className="m-tb">
                  <Col xs={3}>
                    <div className="progress-container">
                      <CustomContentProgressbar
                        visiblity={isVisible}
                        animPercentage={percentage86}
                        animInterval={25}
                        initialAnimation={true}
                        strokeWidth={"15"}
                      >
                        <span style={{ color: "#e11c3a" }}>
                          <strong>n=173</strong>
                        </span>
                        <br />
                        agreed that the disorder
                        <br />
                        is life changing
                      </CustomContentProgressbar>
                    </div>
                  </Col>

                  <Col xs={3}>
                    <div className="progress-container">
                      <CustomContentProgressbar
                        visiblity={isVisible}
                        animPercentage={percentage68}
                        animInterval={15}
                        initialAnimation={true}
                        strokeWidth={"15"}
                      >
                        <span style={{ color: "#e11c3a" }}>
                          <strong>n=135</strong>
                        </span>
                        <br />
                        agreed that they never feel
                        <br />
                        like a "normal" person
                      </CustomContentProgressbar>
                    </div>
                  </Col>

                  <Col xs={3}>
                    <div className="progress-container">
                      <CustomContentProgressbar
                        visiblity={isVisible}
                        animPercentage={percentage80}
                        animInterval={20}
                        initialAnimation={true}
                        strokeWidth={"15"}
                      >
                        <span style={{ color: "#e11c3a" }}>
                          <strong>n=159</strong>
                        </span>
                        <br />
                        agreed that life
                        <br />
                        is a daily struggle
                      </CustomContentProgressbar>
                    </div>
                  </Col>

                  <Col xs={3}>
                    <div className="progress-container">
                      <CustomContentProgressbar
                        visiblity={isVisible}
                        animPercentage={percentage76}
                        animInterval={12}
                        initialAnimation={true}
                        strokeWidth={"15"}
                      >
                        <span style={{ color: "#e11c3a" }}>
                          <strong>n=152</strong>
                        </span>
                        <br />
                        agreed that it has affected
                        <br />
                        important moments
                        <br />
                        in their lives
                      </CustomContentProgressbar>
                    </div>
                  </Col>
                </Row>
              )
            }}
          </VisibilitySensor>

          <Row center="xs">
            <Col xs={12}>
              <img src={infographic2} className="" />
            </Col>
          </Row>

          <VisibilitySensor>
            {({ isVisible }) => {
              let percentage65 = isVisible ? 65 : 0
              let percentage36 = isVisible ? 36 : 0
              let percentage28 = isVisible ? 28 : 0

              return (
                <Row center="xs" className="m-tb">
                  <Col xs={12} md={4}>
                    <div
                      className="progress-container"
                      style={{
                        display: "inline-block",
                        paddingRight: "15px",
                      }}
                    >
                      <CustomContentProgressbar
                        visiblity={isVisible}
                        animPercentage={percentage65}
                        animInterval={20}
                        initialAnimation={true}
                        strokeWidth={"15"}
                      >
                        <span
                          style={{
                            color: "#e11c3a",
                            fontSize: "22px",
                            lineHeight: "22px",
                          }}
                        >
                          <strong>n=130</strong>
                        </span>
                      </CustomContentProgressbar>
                      <br />
                    </div>
                    <div style={{ fontFamily: "arial" }}>
                      reported social changes, like not going out as much to
                      events, dinners, movies, and family&nbsp;events
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div
                      className="progress-container"
                      style={{ display: "inline-block", paddingLeft: "15px" }}
                    >
                      <CustomContentProgressbar
                        visiblity={isVisible}
                        animPercentage={percentage36}
                        animInterval={10}
                        initialAnimation={true}
                        strokeWidth={"15"}
                      >
                        <span
                          style={{
                            color: "#e11c3a",
                            fontSize: "22px",
                            lineHeight: "22px",
                          }}
                        >
                          <strong>n=71</strong>
                        </span>
                      </CustomContentProgressbar>
                      <br />
                    </div>
                    <div style={{ fontFamily: "arial" }}>
                      reported physical changes, like changing jobs, moving, and
                      being unable to cook or bathe when no one is around
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div
                      className="progress-container"
                      style={{ display: "inline-block", paddingLeft: "15px" }}
                    >
                      <CustomContentProgressbar
                        visiblity={isVisible}
                        animPercentage={percentage28}
                        animInterval={10}
                        initialAnimation={true}
                        strokeWidth={"15"}
                      >
                        <span
                          style={{
                            color: "#e11c3a",
                            fontSize: "22px",
                            lineHeight: "22px",
                          }}
                        >
                          <strong>n=57</strong>
                        </span>
                      </CustomContentProgressbar>
                      <br />
                    </div>
                    <div style={{ fontFamily: "arial" }}>
                      reported emotional changes, like not showing emotions as
                      much
                    </div>
                  </Col>
                </Row>
              )
            }}
          </VisibilitySensor>
          <Row center="xs">
            <Col xs={12}>
              <img src={infographic3} className="" />
            </Col>
          </Row>
        </ContentBlock>
        <ContentBlock
          style={{ textAlign: "center" }}
          className="hide-below-635 last"
        >
          <Row center="xs">
            <Col xs={12}>
              <img src={infographic4} />
            </Col>
          </Row>
        </ContentBlock>
        <img
          src={infographicFull}
          alt="Full Infographic Image"
          className="show-below-635"
        />
        <ContentBlock className="">
          <p style={{ textAlign: "center" }}>
            <a href="javascript: void(0)" onClick={downloadBtn}>
              <button>Download PDF</button>
            </a>
          </p>
        </ContentBlock>
      </div>
    </Layout>
  )
}

export default OurFocus
